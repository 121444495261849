import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { rem } from "polished";
import Collapsible from "react-collapsible";

import { SortableList } from "./SortableList";
import { handleUploadClick } from "../../hooks/Cloudinary";
import { VideoPreview } from "../VideoPreview";
import { CtaButton } from "../Button";
import { faFireAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  isDev,
  ProjectState,
  SECTION_LENGTH_IN_SECONDS,
} from "../../helpers/constants";
import { BuildButton } from "./BuildButton";
import { H2, P } from "../Typography";
import { useTimelineContext } from "../../hooks/useTimelineContext";
import { usePlayerContext } from "../../hooks/usePlayerContext";

export type SortableAttributes = {
  chosen?: boolean;
  selected?: boolean;
};

export const Timeline = () => {
  const { project, timeline, assets, sectionsCount, addPictures } =
    useTimelineContext();
  const { playedInSeconds } = usePlayerContext();

  const isLastSecond =
    playedInSeconds > SECTION_LENGTH_IN_SECONDS - 1 &&
    playedInSeconds % SECTION_LENGTH_IN_SECONDS < 1;
  const isFirstSecond =
    playedInSeconds > SECTION_LENGTH_IN_SECONDS - 1 &&
    playedInSeconds % SECTION_LENGTH_IN_SECONDS > SECTION_LENGTH_IN_SECONDS - 1;

  return (
    <Wrapper>
      <Preview fadeIn={isFirstSecond} fadeOut={isLastSecond}>
        <CenterText>
          <SongName>
            "{project.songTitle ?? "Name of the song"}" by {project.artistName}
          </SongName>
        </CenterText>

        <VideoPreview />
        <VideoPreviewDisclaimer>
          The player above is for preview purposes only and your photos may look
          cut off or zoomed in to fill the screen. Once you finalize your video,
          we'll deliver a refined version in HD quality with full photos in
          frames and smooth transitions.
        </VideoPreviewDisclaimer>
        <BuildButton />

        {[ProjectState.inprogress, ProjectState.released].includes(
          project.state,
        ) ||
          (project.remainingRenders < 3 && (
            <AlertWrapper>
              <InfoIcon />
              <P>
                A current version of your video slideshow already exists on your
                song page. If you decide to make and submit any changes below,
                we will overwrite your existing video and it will no longer be
                accessible.
              </P>
              <P>
                You have <strong>{project?.remainingRenders ?? "?"}</strong>
                &nbsp;remaining edits.
              </P>
            </AlertWrapper>
          ))}

        <UploadPhotosWrapper>
          <CenterText>
            <H2>Upload Photos</H2>
            <P>
              You can upload up to <strong>{sectionsCount}</strong> photos to
              fill your photo slideshow
            </P>

            <CtaButton
              id="upload_widget"
              onClick={() =>
                handleUploadClick({
                  callback: (error, result) => {
                    addPictures(error, result);
                  },
                })
              }
            >
              Upload files
            </CtaButton>
          </CenterText>
        </UploadPhotosWrapper>
      </Preview>

      <SortableList />

      {isDev && (
        <Collapsible trigger="🐛 Debug">
          <pre>
            <code>
              {JSON.stringify(
                {
                  timeline: timeline?.map(
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    ({ chosen, selected, ...item }) => item,
                  ),
                  assets,
                },
                null,
                2,
              )}
            </code>
          </pre>
        </Collapsible>
      )}
    </Wrapper>
  );
};

export const Grid = styled.div`
  margin: 0;

  @media screen and (min-width: 321px) {
    margin: 0 1rem;
  }

  html {
    touch-action: manipulation;
  }

  @media screen and (min-width: ${rem(1200)}) {
    margin: auto;
    max-width: ${rem(1024)};
  }

  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 0.25rem;

    @media screen and (min-width: ${rem(768)}) {
      padding-bottom: 2rem;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (min-width: ${rem(1200)}) {
      padding-bottom: 3rem;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    column-gap: 1rem;
    row-gap: 1rem;
  }

  .grid--list {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 1rem;
    column-gap: 1rem;
    row-gap: 1rem;
  }

  ul,
  ol,
  dl {
    padding: 0;
  }

  li + li {
    margin: 0;
  }
`;

const Wrapper = styled.div``;

export const Preview = styled.div<{
  fadeIn?: boolean;
  fadeOut?: boolean;
}>`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  margin: auto;
  padding-top: 1rem;

  @keyframes slideFadeIn {
    0% {
      transform: translateX(100%);
    }

    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slideFadeOut {
    0% {
      transform: translateX(0%);
    }

    100% {
      transform: translateX(-100%);
    }
  }

  overflow: hidden;

  img {
    transition: 1s opacity ease-in-out;

    ${({ fadeIn }) =>
      fadeIn &&
      css`
        opacity: 0;
      `}

    ${({ fadeOut }) =>
      fadeOut &&
      css`
        opacity: 0;
      `}

    max-width: 100%;
    clear: both;
    background: lightgray;
  }

  @media screen and (min-width: ${rem(768)}) {
    margin: 0 auto;
  }
`;

export const UploadPhotosWrapper = styled.div`
  text-align: center;
  padding: 1rem;

  button {
    margin: 1rem auto;

    width: fit-content;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
`;

export const AlertWrapper = styled(UploadPhotosWrapper)`
  flex-direction: column;
  border: 1px solid #000000;
`;

const CenterText = styled.div`
  width: ${rem(300)};

  margin: 0 auto 1rem auto;

  @media screen and (min-width: ${rem(768)}) {
    width: ${rem(600)};
  }
`;

const SongName = styled(H2)`
  color: #f64d1c;
  margin-bottom: 0;
  padding-top: 1rem;
`;

const VideoPreviewDisclaimer = styled(P)`
  text-align: center;
  margin: auto;

  width: 270px;

  @media screen and (min-width: 321px) {
    width: ${rem(300)};
  }

  @media screen and (min-width: ${rem(768)}) {
    width: ${rem(600)};
  }
`;

const InfoIcon = styled((props: PropsWithChildren<unknown>) => (
  <div {...props}>
    <FontAwesomeIcon icon={faFireAlt} />
  </div>
))`
  font-size: 3rem;
`;
