import React, { useEffect, useMemo } from "react";
import { SkeletonTheme } from "react-loading-skeleton";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  GetDurationBetweenContextProvider,
  PlayerContextProvider,
} from "../hooks/PlayerContext";
import { Timeline } from "../components/timeline/Timeline";
import { Grid } from "../components/Grid";
import { HeaderWithBackToDashboardButton } from "../components/Header";
import { Footer } from "../components/Footer";
import { getProjectFromDto, projectService } from "../service/projectService";
import {
  getTokenProjectId,
  isAdminTokenValid,
  isTokenValid,
  isUserTokenValid,
  setToken,
  useUrlQuery,
} from "../helpers/token";
import { TimelineContextProvider } from "../hooks/TimelineContext";
import { ProjectState } from "../helpers/constants";
import { ProjectDto } from "../service/types";

export const TimelinePageAuthInterceptor = () => {
  const query = useUrlQuery();
  const navigate = useNavigate();

  const token = query.get("token") || "";
  if (token && isTokenValid(token)) {
    setToken({ token });
  }

  const isUser = isUserTokenValid();
  const isAdmin = isAdminTokenValid();

  const projectId: string = isUser
    ? getTokenProjectId()
    : query.get("projectId") || "";

  useEffect(() => {
    if (!isTokenValid()) {
      return navigate("/session-expired");
    }

    if (isAdmin && !projectId) {
      return navigate("/admin");
    }

    if (!projectId) {
      throw new Error("Missing projectId");
    }
  }, [isAdmin, navigate, projectId]);

  return <TimelinePage projectId={projectId} />;
};

export const TimelinePage = ({ projectId }: { projectId: string }) => {
  const navigate = useNavigate();
  const { error, data } = useQuery({
    queryKey: ["projectData", projectId],
    queryFn: () => projectService.getProject(projectId),
    refetchOnWindowFocus: false,
    enabled: Boolean(projectId),
  });

  const isUser = isUserTokenValid();

  if (error) {
    throw error;
  }

  const projectDto: ProjectDto | undefined = useMemo(() => data?.data, [data]);
  const project = useMemo(() => getProjectFromDto(projectDto), [projectDto]);

  useEffect(() => {
    if (
      isUser &&
      [ProjectState.inprogress, ProjectState.failed].includes(project.state)
    ) {
      return navigate("/in-progress");
    }

    if (isUser && [ProjectState.locked].includes(project.state)) {
      return navigate("/in-revision");
    }

    if (isUser && project.remainingRenders === 0) {
      return navigate("/no-more-video-renders");
    }
  }, [isUser, navigate, project]);

  return (
    <SkeletonTheme baseColor="#f2f0e6" highlightColor="#d7d2b8">
      <PlayerContextProvider songUrl={project.songUrl}>
        <TimelineContextProvider project={project}>
          <GetDurationBetweenContextProvider>
            <>
              <HeaderWithBackToDashboardButton projectState={project.state} />
              <Grid>
                <Timeline />
              </Grid>

              <Footer />
            </>
          </GetDurationBetweenContextProvider>
        </TimelineContextProvider>
      </PlayerContextProvider>
    </SkeletonTheme>
  );
};
