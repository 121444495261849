import axios from "axios";
import { history } from "../helpers/history";
import {
  makeAuthorizationHeader,
  isTokenValid,
  getToken,
  setToken,
  tokenIsExpired,
} from "../helpers/token";

export const apiUrl = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: apiUrl,
  headers: {
    Accept: "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    if (!config.baseURL) {
      console.log({ config, apiUrl });
      throw Error("Missing API base url");
    }

    const { origin } = new URL(config.baseURL as string);

    const allowedOrigins = [apiUrl];

    const token = getToken() as string;

    if (
      allowedOrigins.includes(origin) &&
      isTokenValid(token) &&
      !tokenIsExpired(token)
    ) {
      config.headers.authorization = makeAuthorizationHeader(token);
    }

    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    //response interceptor to refresh token on receiving token expired error
    const refreshedToken = response.headers["x-refreshed-jwt"];

    if (refreshedToken) {
      setToken({ refreshedToken });
    }
    return response;
  },
  (error: { response: { status: number } }) => {
    //response interceptor for status 409 - locked state require redirect to in-revision page
    if (error?.response?.status === 409) {
      history.push("/in-progress");
      return;
    }

    return Promise.reject(error);
  },
);

export default instance;
