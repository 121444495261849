import { useEffect } from "react";

import { useTimelineContext } from "../hooks/useTimelineContext";
import { TimelineListType } from "../service/types";

export type TimelineGapMetrics = {
  isValid?: boolean;
  listMetrics: ItemMetric[];
  listLength: number;
  hasGaps: boolean;
  gapIndexes: number[];
  uploadsLength: number;
};

export enum ItemMetric {
  upload,
  empty,
  gap,
}

export const getTimelineGapMetrics = (
  timeline: TimelineListType,
): TimelineGapMetrics => {
  const length = timeline?.length;
  const listOfIsUpload = (Array.isArray(timeline) ? timeline : []).map(
    (item) => (item.publicId ? ItemMetric.upload : ItemMetric.empty),
  );
  const { listMetrics, gapIndexes, hasGaps } = listOfIsUpload.reduceRight(
    (
      acc: {
        listMetrics: ItemMetric[];
        gapIndexes: number[];
        hasGaps: boolean;
      },
      type,
      index,
    ) => {
      const isUpload = type === ItemMetric.upload;
      const isNextUpload = listOfIsUpload[index + 1] === ItemMetric.upload;
      const hasGaps = acc.hasGaps || (!isUpload && isNextUpload);
      const isGap = Boolean(hasGaps && !isUpload);
      return {
        listMetrics: [
          isUpload
            ? ItemMetric.upload
            : isGap
            ? ItemMetric.gap
            : ItemMetric.empty,
          ...acc.listMetrics,
        ],
        gapIndexes: isGap ? [...acc.gapIndexes, index] : acc.gapIndexes,
        hasGaps,
      };
    },
    { listMetrics: [], gapIndexes: [], hasGaps: false },
  );

  return {
    listMetrics,
    listLength: length,
    hasGaps,
    gapIndexes,
    uploadsLength: listOfIsUpload.filter((is) => is === ItemMetric.upload)
      .length,
  };
};

export const useExtendedTimelineMetrics = ({
  minimumPercentage = 50,
}: {
  minimumPercentage?: number;
} = {}) => {
  const { timeline, setSubmittedAt } = useTimelineContext();

  const { listMetrics, listLength, hasGaps, gapIndexes, uploadsLength } =
    getTimelineGapMetrics(timeline);

  const completedPercentage = (uploadsLength / listLength) * 100;
  const missingToMinimumPercentage = Math.max(
    minimumPercentage - completedPercentage,
    0,
  );
  const isValid = completedPercentage >= minimumPercentage && !hasGaps;

  useEffect(() => {
    if (isValid) {
      setSubmittedAt?.("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid]);

  const missingToMinimum = Math.max(
    Math.ceil(listLength / (100 / minimumPercentage)) - uploadsLength,
    0,
  );
  const missingToMaximum = listLength - uploadsLength - missingToMinimum;

  const firstInvalidIndex = listMetrics.findIndex((item) =>
    [ItemMetric.gap, ItemMetric.empty].includes(item),
  );
  const fistInvalidId = timeline[firstInvalidIndex]?.id;

  return {
    isValid,
    listMetrics,
    listLength,
    hasGaps,
    gapIndexes,
    uploadsLength,
    completedPercentage,
    missingToMinimumPercentage,
    missingToMinimum,
    missingToMaximum,
    fistInvalidId,
  };
};
