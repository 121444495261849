import axios from "./axiosInstance";
import { ProjectState } from "../helpers/constants";
import { Asset, Assets, Project, ProjectDto, TimelineListType } from "./types";

export const getProjectFromDto = (projectDto?: ProjectDto): Project => {
  if (!projectDto) {
    return {
      id: "",
      customerEmail: "",
      songTitle: "",
      artistName: "",
      songUrl: "",
      videoUrl: "",
      videoUrlHost: null,
      state: ProjectState.created,
      createdAt: "",
      updatedAt: "",
      timeline: [],
      assets: {},
      remainingRenders: 3,
    };
  }

  return {
    ...projectDto,
    songUrl: projectDto?.songUrl ?? "",
    songTitle: projectDto?.songTitle ?? "",
    state: projectDto?.state ?? ProjectState.created,
    timeline: Array.isArray(projectDto?.timeline) ? projectDto.timeline : [],
    assets: (Array.isArray(projectDto?.assets) ? projectDto.assets : []).reduce(
      (assets: Assets, asset: Asset) => ({
        ...assets,
        [asset.publicId]: asset,
      }),
      {},
    ),
  };
};

export const projectService = {
  getProject: (projectId: string) =>
    axios.get<ProjectDto>(`api/projects/${projectId}`),
  postTimeline: ({
    projectId,
    timeline,
  }: {
    projectId: string;
    timeline: TimelineListType;
  }) => axios.post(`api/projects/${projectId}/timeline`, timeline),
  postAssets: ({ projectId, assets }: { projectId: string; assets: Assets }) =>
    axios.post(`api/projects/${projectId}/assets`, Object.values(assets)),
  patchRender: ({ projectId }: { projectId: string }) =>
    axios.patch(`api/projects/${projectId}/render`),
};
