import React, { PropsWithChildren } from "react";
import styled from "@emotion/styled";
import { GhostButton } from "../Button";
import { Image, Transformation } from "cloudinary-react";
import { cloud_name } from "../../hooks/Cloudinary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faMagic } from "@fortawesome/free-solid-svg-icons";
import { css } from "@emotion/react";
import { useGetDurationBetweenContext } from "../../hooks/useGetDurationBetweenContext";

export const Picture = ({
  index,
  publicId,
}: {
  index: number;
  publicId?: string;
}) => {
  const { getDurationBetween } = useGetDurationBetweenContext();
  const durationBetween = getDurationBetween(index);

  return (
    <Image
      cloudName={cloud_name}
      public_id={publicId}
      min-width="300"
      min-height="200"
      alt={durationBetween}
    >
      <Transformation width="300" height="200" gravity="faces" crop="fill" />
    </Image>
  );
};

export const EmptyPicture = styled(
  ({
    isLooped,
    ...props
  }: PropsWithChildren<{
    isInvalid?: boolean;
    isGap?: boolean;
    isLooped?: boolean;
  }>) => (
    <div {...props}>
      <FontAwesomeIcon icon={isLooped ? faMagic : faFileImage} />
    </div>
  ),
)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(215, 210, 184, 0.5);
  border: 0.5rem solid rgba(212, 210, 196, 1);
  color: rgba(0, 0, 0, 0.5);
  width: 100%;

  ${({ isLooped }) => isLooped && css``}

  ${({ isGap, isInvalid }) =>
    (isGap || isInvalid) &&
    css`
      color: rgba(235, 106, 110, 0.5);
    `}

  ${({ isGap, isInvalid }) =>
    isGap &&
    isInvalid &&
    css`
      color: #f64d1c;
      border-color: rgba(235, 106, 110, 0.125);
    `}
`;

export const RemoveButton = styled(GhostButton)`
  margin-top: auto;
  color: #d61e12;

  &:hover {
    color: #ff9677;
  }
  &:active {
    color: #cd4c27;
  }
  &:disabled {
    color: #cfcfcf;
  }
`;
