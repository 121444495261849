import { Asset, AssetId, Assets } from "../service/types";

export const cloud_name = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const upload_preset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
export const upload_folder = process.env.REACT_APP_CLOUDINARY_UPLOAD_FOLDER;

const sources = [
  "local",
  "camera",
  "url",
  "image_search",
  // "dropbox",
  // "facebook",
  // "instagram",
];

const cloudinaryConfig = {
  cloud_name,
  upload_preset,
  sources,
  styles: {
    palette: {
      window: "#f2f0e6",
      windowBorder: "#9f9f9f",
      tabIcon: "#000000",
      menuIcons: "#A4A4A4",
      textDark: "#000000",
      textLight: "#FFFFFF",
      link: "#f64d1c",
      action: "#f64d1c",
      inactiveTabIcon: "#FFb5A0",
      error: "#d61e12",
      inProgress: "#36b5cf",
      complete: "#27A012",
      sourceBg: "#f2f0e6",
    },
    fonts: {
      default: null,
      "National-Book, sans-serif": {
        url: "https://res.cloudinary.com/songfinch/raw/upload/v1630661873/fonts/National-Book.otf",
        active: true,
      },
    },
  },
};

export type CloudinaryAsset = Readonly<{
  asset_id: AssetId; // "1389c34de9b0e8b5143475738545eca5",
  public_id: string; // "songfinch/cat2_kye6wu",
  version: number; // 1613330265,
  version_id: string; // "485ba8425b54d0b4b05c9631a467b57a",
  signature?: string; // "48bfd7c4c236b00205dd2e5bab31092573c8ace5",
  width?: number; //  300,
  height?: number; //  168,
  format?: string; // "jpg",
  resource_type?: string; // "image",

  created_at?: string; // "2021-02-14T19:17:45Z",
  tags?: string[]; // [],
  bytes?: number; //  5836,
  type?: string; // "upload",
  etag?: string; // "ffe2f4b2b0efcadf7a9d8ad312e6794f",
  placeholder?: false;
  url?: string; // "http://res.cloudinary.com/reactera-com/image/upload/v1613330265/songfinch/cat2_kye6wu.jpg",
  secure_url?: string; // "https://res.cloudinary.com/reactera-com/image/upload/v1613330265/songfinch/cat2_kye6wu.jpg",
  access_mode?: string; // "public",
  existing?: false;
  original_filename?: string; // "cat2",
  original_extension?: string; // "jpeg",
  path?: string; // "v1613330265/songfinch/cat2_kye6wu.jpg",
  thumbnail_url?: string; // "https://res.cloudinary.com/reactera-com/image/upload/c_limit,h_60,w_90/v1613330265/songfinch/cat2_kye6wu.jpg",
}>;

export const getAssetsFromCloudinaryAsset = (result: CloudinaryFile[] = []) =>
  result
    .filter((file) => file.status === "success")
    .map((file) => file.uploadInfo)
    .map(
      ({
        asset_id,
        public_id,
        version,
        version_id,
        signature,
        width,
        height,
        format,
        bytes,
        resource_type,
        created_at,
      }: CloudinaryAsset) =>
        ({
          assetId: asset_id,
          publicId: public_id,
          version,
          versionId: version_id,
          signature,
          width,
          height,
          format,
          bytes,
          resourceType: resource_type,
          createdAt: created_at,
        }) as Asset,
    )
    .reduce(
      (assets: Assets, asset: Asset) => ({
        ...assets,
        [asset.assetId]: asset,
      }),
      {},
    );

export type CloudinaryFile = Readonly<{
  status: string;
  uploadInfo: CloudinaryAsset;
}>;

type Info = Readonly<{ files: CloudinaryFile[] }>;
export type CloudinaryUploadError = any; // TODO: define
export type CloudinaryUploadResult = { event: string; info: Info };

export const handleUploadClick = ({
  folder = "",
  config = cloudinaryConfig,
  callback = (
    error: CloudinaryUploadError,
    result: CloudinaryUploadResult,
  ) => {},
}) => {
  // @ts-ignore
  cloudinary.openUploadWidget(
    {
      ...config,
      folder: [upload_folder, folder].filter((s) => s?.length).join("/"),
    },
    (error: CloudinaryUploadError, result: CloudinaryUploadResult) => {
      callback(error, result);
    },
  );
};
